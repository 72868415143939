<template>
  <div class="detail_container">
    <div class="header">
      <div class="picker">
        <van-field
          readonly
          clickable
          label="选择订单日期"
          :value="date"
          @click="datePickerShow = true"
          right-icon="arrow-down"
          class="picker"
        />
        <van-popup
          v-model="datePickerShow"
          round
          position="bottom"
        >
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
            @confirm="dateConfirm"
            @cancel="dateCancel"
          />
        </van-popup>
      </div>
      <van-notice-bar
        color="#1989fa"
        background="#ecf9ff"
        left-icon="info-o"
      >温馨提示：默认查询7日内的订单记录</van-notice-bar>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item,index) in recordList"
        :key="index"
      >
        <div>住院人：{{item.InName}}</div>
        <div>金额：{{item.payAmount}}元</div>
        <div>支付方式：{{item.payMode}}</div>
        <div>支付时间：{{item.payTime}}</div>
        <van-tag
          type="success"
          plain
          size="large"
          v-if="item.feeType === '1'"
        >已&nbsp;缴&nbsp;费</van-tag>
        <van-tag
          type="danger"
          plain
          size="large"
          v-if="item.feeType === '2'"
        >已&nbsp;退&nbsp;费</van-tag>
      </div>
    </div>
    <van-empty
      description="暂无内容"
      v-if="recordList === ''"
    />
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Detail",
  data() {
    return {
      minDate: new Date(1800, 0, 1),
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      currentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      date: "",
      datePickerShow: false,
      end: "",
      admissionNum: "",
      recordList: []
    };
  },
  created() {
    this.admissionNum = this.$route.query.admissionNum;
    this.date = this.formatTime(-6);
    this.end = this.formatTime(0);
    this.getHospitalDepositPayOrRefundRecords();
  },
  methods: {
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return `${val}日`;
    },
    formatTime(num) {
      let now = new Date();
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let ShowTime = nowTime + num * oneDayTime;
      let currentShowTime = nowTime + -6 * oneDayTime;
      let myDate = new Date(ShowTime);
      let currentDate = new Date(currentShowTime);
      let y = myDate.getFullYear(); //年
      let m =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let d = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate(); //日
      this.currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      return y + "-" + m + "-" + d;
    },
    dateFormat(dateData) {
      let date = new Date(dateData);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let time = y + "-" + m + "-" + d;
      let today = new Date().getFullYear();
      return time;
    },
    dateConfirm(value) {
      this.date = this.dateFormat(value);
      this.datePickerShow = false;
      this.getHospitalDepositPayOrRefundRecords();
    },
    dateCancel() {
      this.datePickerShow = false;
    },
    async getHospitalDepositPayOrRefundRecords() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/his/getHospitalDepositPayOrRefundRecords",
        {
          admissionNum: this.admissionNum,
          beginDate: this.date,
          endDate: this.end
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.recordList = res.data.item;
      } else {
        this.recordList = "";
        this.$toast.fail(res.msg);
      }
    }
  }
};
</script>

<style scope>
.detail_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}
.detail_container .header .picker .van-cell {
  padding: 30px;
}
.detail_container .header .picker .van-cell::after {
  border: 0;
}
.detail_container .header .picker .van-field__label {
  text-align: left;
  font-size: 40px;
}
.detail_container .header .picker .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}
.detail_container .header .picker .van-icon {
  font-size: 40px;
}
.detail_container .header .picker .van-picker-column {
  font-size: 40px;
}
.detail_container .header .picker .van-picker__toolbar {
  height: 150px;
}
.detail_container .header .picker .van-picker__cancel,
.detail_container .header .picker .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}
.detail_container .header .van-notice-bar__content {
  font-size: 32px;
}
.detail_container .header .van-notice-bar__left-icon {
  font-size: 32px;
}
.detail_container .list {
  padding-bottom: 60px;
}
.detail_container .list .item {
  position: relative;
  padding: 20px 30px;
  font-size: 36px;
  border-bottom: 1px solid #f5f5f5;
  line-height: 70px;
}
.detail_container .list .item .van-tag {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  font-size: 36px;
  padding: 20px;
}
</style>